<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button @click="showCreateEditModal()">生成绩效</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>员工姓名</span>
              </template>
              <a-input v-model.trim="searchParams.search.realname" placeholder="请输入姓名" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>手机号</span>
              </template>
              <a-input v-model.trim="searchParams.search.telphone" placeholder="请输入手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-row :gutter="32">
      <a-col :span="8">
        <div class="table">
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <a-table size="middle" :pagination="false" :bordered='false' rowKey="employee_id"
            :columns="columns" :dataSource="list" :customRow="clickRow">
              <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
              </template>
          </a-table>
        </div>
        <div class="page">
          <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
              :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
          >
              <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
              </template>
            </a-pagination>
        </div>
      </a-col>
      <a-col :span="16">
        <div class="table">
          <vue-element-loading :active="loadingC" color="#00cca2" spinner="spinner"/>
          <a-table size="small" :pagination="false" :bordered='false' rowKey="class_id"
            :columns="columns2" :dataSource="listC">
              <template slot="index" slot-scope="text, record , index">
                <span>{{index+1}}</span>
              </template>
              <template slot="class_calculation_rule" slot-scope="text, record , index">
                <a href="javascript:;"  @click="showEditModal(record)" v-if="text">{{text.rule_name}}</a>
                <span v-if="!text" @click="showEditModal(record)">{{text?text.rule_name:'未设置'}}</span>
              </template>
              <template slot="action" slot-scope="text,record">
                <a href="javascript:;" @click="showEditModal(record)">设置</a>
              </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <editModal v-if="isEditModal" :item='modalData' />
    <createEditModal v-if="isCreateEditModal" :employee_name='employee_name'  :employee_id='employee_id' />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '员工姓名', dataIndex: 'employee_name', key: 'employee_name' },
  { title: '联系方式', dataIndex: 'employee_cellphone', key: 'employee_cellphone' },
]
const columns2 = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '班级名称', dataIndex: 'class_name', key: 'class_name' },
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id' },
  { title: '绩效名称', dataIndex: 'class_calculation_rule', key: 'class_calculation_rule' ,scopedSlots: { customRender: 'class_calculation_rule' }},
  { title: '操作', key: 'operation', align: 'right', width: 100, scopedSlots: { customRender: 'action' }}
]

    import editModal from './editModal'
    import createEditModal from './createEditModal'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'setup',
        mixins: [ tableMixins ],
        data() {
            return {
                loading: false,
                loadingC: false,
                isEditModal: false,
                isCreateEditModal: false,
                columns,
                columns2,
                list:[],
                listC:[],
                employee_id:'',
                employee_name:'',
            }
        },
        provide() {
          return {
            parent: this
          }
        },
        components: {
          editModal,
          createEditModal
        },
        methods: {
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('financeSalaryCalculationTeacherAction', { data: this.searchParams })
                this.list = res.items
                if(res.items.length > 0){
                  this.employee_id = res.items[0].employee_id
                  this.employee_name = res.items[0].employee_name
                  this.getCalculation()
                }
                this.pageParams = res._meta
                this.loading = false
            },
            async getCalculation(){
              this.loadingC = true
              let res = await this.$store.dispatch('financeSalaryCalculationCalculationAction', { data: {employee_id:this.employee_id} })
              this.listC = res.data.class_lists
              this.loadingC = false
            },
            clickRow(record, index) {
              return {
                  on: {
                      click: () => {
                          this.employee_id = record.employee_id
                          this.employee_name = record.employee_name
                          this.getCalculation()
                      }
                  }
              }
            },
            showEditModal(record) {
                this.modalData = {
                  employee_id:this.employee_id,
                  class_id:record.class_id,
                  class_calculation_rule:record.class_calculation_rule
                }
                this.isEditModal = true
            },
            showCreateEditModal(){
              this.isCreateEditModal = true
            },
            hideEditModal(val){
                if(val){
                  this.getCalculation()
                }
                this.isEditModal = false
                this.isCreateEditModal = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>