<template>
    <a-modal :title="title" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" okText="确认" width="400px" 
        @ok="handleOk" @cancel="handleCancel">
            <a-form :form="form" layout="vertical">
                <a-form-item v-for="(item,index) in formData" :key="index" :label="item.label">
                    <a-range-picker v-if="item.type === 'date'" :disabled-date="disabledDate" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
                </a-form-item>
            </a-form>
    </a-modal>
</template>

<script>
const formData = [
    {
        name: 'attend_time',
        label: '结算时间',
        type: 'date',
        rules: [{ required: true, message: '请选择结算时间!' }],
    }
]
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:"attendModal",
        inject: ['parent'],
        mixins:[ranges],
        props:{
            item:Object
        },
        data() {
            return {
                formData,
                title:'',
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        async created () {
            this.visible = true
            this.title = `${this.item.rule_name} 重新结算`
        },
        methods: {
            async handleOk(){
                let params = await this.form.validateFields()
                let obj = {
                    rule_id:this.item.rule_id,
                    start_date:moment(params.attend_time[0]).format("YYYY-MM-DD"),
                    end_date:moment(params.attend_time[1]).format("YYYY-MM-DD"),
                }
                this.confirmLoading = true
                await this.$store.dispatch('financeSalaryCalculationReAction', { data: obj })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideReview(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            handleCancel(){
                this.parent.hideReview()
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>