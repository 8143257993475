<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index"
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input style='width:95%;margin-right:5px' v-if="item.type === 'inputs'" v-decorator="[item.name, { rules: item.rules }]"/><span v-if="item.type === 'inputs'">{{item.items.unit}}</span>
        <a-radio-group @change="handleRadios" v-else-if="item.type === 'radios'" :disabled="isEdit?true:false" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>
        <a-radio-group v-else-if="item.type === 'radio'" :disabled="isEdit?true:false" @change="handleRadio" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>
        <a-radio-group v-else-if="item.type === 'radioes'" :disabled="isEdit?true:false" @change="handleRadioes" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" mode="multiple" :maxTagCount='2' :maxTagTextLength='5' showSearch :filterOption="filterOption"
           v-decorator="[item.name, { rules: item.rules }]" @search="handleSearch">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <div v-else-if="item.type === 'self'" class="salary-self">
            <div v-for="(item,index) in rule_level" :key="index" v-if="radiosIndex === 1">
                <span>{{radioIndex === 1?'超过':'满'}}</span> <a-input-number @change="e=>handleNumber(e,index,'over_number')" :min="0" :value="item.over_number"/> 课次, 每课次金额 <a-input-number @change="e=>handleNumber(e,index,'over_salary')" :min="0" :value="item.over_salary"/> 元 <a-icon v-if="rule_level.length > 1" type="delete" @click="handleDel(index)" />
            </div>
            <div v-for="(item,index) in rule_level" :key="index" v-if="radiosIndex === 2 || radiosIndex === 3">
                <span>{{radioIndex === 1?'超过':'满'}}</span> <a-input-number @change="e=>handleNumber(e,index,'over_number')" :min="0" :value="item.over_number"/> 课时, 每课时金额 <a-input-number @change="e=>handleNumber(e,index,'over_salary')" :min="0" :value="item.over_salary"/> 元 <a-icon v-if="rule_level.length > 1" type="delete" @click="handleDel(index)" />
            </div>
            <div v-for="(item,index) in rule_level" :key="index" v-if="radiosIndex === 4 || radiosIndex === 10">
                <span>{{radioIndex === 1?'超过':'满'}}</span> <a-input-number @change="e=>handleNumber(e,index,'over_number')" :min="0" :value="item.over_number"/> 元, 提成比例 <a-input-number @change="e=>handleNumber(e,index,'over_salary_precent')" :min="0" :value="item.over_salary_precent"/> % <a-icon v-if="rule_level.length > 1" type="delete" @click="handleDel(index)" />
            </div>
            <div v-for="(item,index) in rule_level" :key="index" v-if="radiosIndex === 5">
                超过 <a-input-number @change="e=>handleNumber(e,index,'over_number')" :min="0" :value="item.over_number"/> 人次, 每人次金额 <a-input-number @change="e=>handleNumber(e,index,'over_salary')" :min="0" :value="item.over_salary"/> 元 <a-icon v-if="rule_level.length > 1" type="delete" @click="handleDel(index)" />
            </div>
            <div class="salary-self-add" @click="handleAdd">
                <a-icon type="plus-circle" />
                <span class="salary-self-add-btn">添加规则</span>
            </div>
        </div>

        <a-checkbox-group
          v-else-if="item.type === 'contract_type_ids'"
          name="checkboxgroup"
          :options="plainOptions"
          v-decorator="[item.name, { rules: item.rules }]"
        />

        <a-tree-select
          v-else-if="item.type === 'studio_ids'"
          v-decorator="[item.name, { rules: item.rules }]"
          tree-checkable
          allowClear
          :tree-data="seleteItems.studios"
          :maxTagCount='3'
          :maxTagTextLength='5'
          :replaceFields="{
            title:'studio_name',
            value: 'studio_id',
          }"
          :dropdownStyle="{maxHeight:'400px'}"
          :show-checked-strategy="SHOW_ALL"
          placeholder="请选择应用校区"
        />

        <a-tree-select
          v-else-if="item.type === 'channel_ids'"
          v-decorator="[item.name, { rules: item.rules }]"
          :tree-data="channels"
          tree-checkable
          allowClear
          treeNodeFilterProp="title"
          :maxTagCount='3'
          :maxTagTextLength='5'
          :dropdownStyle="{maxHeight:'400px'}"
          :show-checked-strategy="SHOW_ALL"
          placeholder="请选择来源渠道"
        />
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'commision_type',
    label: '计算类型',
    type: 'radioes',
    rules: [{ required: true, message: '请选择计算类型!' }],
    items: {
      data: 'commisionType',
      label: 'label',
      value: 'value'
    },
  },
  {
    name: 'rule_name',
    label: '规则名称',
    type: 'input',
    rules: [{ required: true, message: '请输入规则名称!' }]
  },
  {
    name: 'rule_type',
    label: '提成方式',
    type: 'radios',
    rules: [{ required: true, message: '请选择提成方式!' }],
    items: {
      data: 'commissionType',
      label: 'label',
      value: 'value'
    },
  },
  {
    name: 'basic_fee',
    label: '基础每课次金额',
    type: 'inputs',
    rules: [{ required: true, message: '请输入金额!' }],
    items:{unit:'元'}
  },
  {
    // 空的为占位对象,请勿删除
  },
  {
    // 空的为占位对象,请勿删除
  },
  {
    name: 'rule_level_type',
    label: '梯度规则',
    type: 'radio',
    rules: [{ required: true, message: '请选择梯度规则!' }],
    items: {
      data: 'gradientRule',
      label: 'label',
      value: 'value'
    },
  },
  {
    name: 'rule_level',
    label: '计算规则',
    type: 'self',
  },
  {
    name: 'studio_ids',
    label: '应用校区',
    type: 'studio_ids',
    rules: [{ required: true, message: '请选择应用校区!' }],
    items:{
      data:'studios',
      label: 'studio_name',
      value: 'studio_id'
    }
  },
  {
    // 空的为占位对象,请勿删除
  },
  {
    name: 'course_cate_ids',
    label: '应用科目',
    type: 'select',
    rules: [{ required: true, message: '请选择应用科目!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    // 空的为占位对象,请勿删除
  }


]
    import C_ITEMS from '@/utils/items'
    import { TreeSelect } from 'ant-design-vue'
    const SHOW_ALL = TreeSelect.SHOW_ALL
    const SHOW_PARENT = TreeSelect.SHOW_PARENT
    export default {
        name:'editModal',
        inject: ['parent'],
        props: {
          item:Object,
          isEdit:Number
        },
        data() {
            return {
              labelCol: { span: 5 },
              wrapperCol: { span: 18 },
              ModalTitle: '新增绩效规则',
              visible: false,
              loading: false,
              confirmLoading: false,
              formData:[...formData],
              form: this.$form.createForm(this),
              seleteItems: {
                commissionType:C_ITEMS.commissionType,
                commisionType:C_ITEMS.commisionType,
                gradientRule:C_ITEMS.gradientRule,
                studios:[],
                classCategorys: []
              },
              rule_level:[{over_number:0,over_salary:0}],
              radiosIndex:1,
              radioIndex:1,
              radioesIndex:1,
              channels:[],
              SHOW_ALL,
              SHOW_PARENT,
              commissionType:[{label:'签约金额',value:10}],
              plainOptions: [
                { label: '新签', value: '1' },
                { label: '续费', value: '2' },
                { label: '转介绍', value: '3' },
              ]
            }
        },
        async created () {
            this.visible = true
            this.loading = true
            await this.getStudio()
            await this.getSourceChannel()
            await this.getClassCategory()
            await this.$nextTick()
            if(this.item){
              this.setForm()
            }else{
              this.form.setFieldsValue({
                rule_type:1,
                rule_level_type:1,
                commision_type:1
              })
              this.loading = false
            }
            
        },
        methods: {
            filterOption(input, option) {
              return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async handleOk() {
                const { item, form } = this
                let params = await form.validateFields()
                params.rule_level = this.rule_level
                if(this.item){
                  params.rule_id = this.item.rule_id
                }
                this.confirmLoading = true
                await this.$store.dispatch(item?'financeSalaryCalculationUpdateRulesAction':'financeSalaryCalculationAddRulesAction', { data: params })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.success('操作失败~')
                    this.confirmLoading = false
                })
            },
            async getStudio() {
              let res = await this.$store.dispatch('cCategoryStudiosAction', {})
              this.seleteItems.studios = res.data
            },
            async getSourceChannel(id) {
              let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
              this.channels = res.data[0].children
            },
            async getClassCategory(val) {
              let res = await this.$store.dispatch('searchCourseTypeAction', {params:val})
              this.seleteItems.classCategorys = res.data
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            handleAdd(){
                let obj = {}
                if(this.radiosIndex == 4 || this.radiosIndex == 10){
                  obj = {over_number:0,over_salary_precent:0}
                }else{
                  obj = {over_number:0,over_salary:0}
                }
                this.rule_level.push(obj)
            },
            handleDel(index){
                this.rule_level.splice(index,1)
            },
            handleNumber(e,index,name){
                this.rule_level[index][name] = e || 0
            },
            handleRadios(e){
              let obj = {}
              let level_type = {
                    name: 'rule_level_type',
                    label: '梯度规则',
                    type: 'radio',
                    rules: [{ required: true, message: '请选择梯度规则!' }],
                    items: {
                      data: 'gradientRule',
                      label: 'label',
                      value: 'value'
                    },
              }
              this.radiosIndex = e.target.value
              if(e.target.value === 1){
                obj = {
                  name: 'basic_fee',
                  label: '基础每课次金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                this.formData[6] = level_type
                this.rule_level = [{over_number:0,over_salary:0}]
              }
              if(e.target.value === 2 || e.target.value === 3){
                obj = {
                  name: 'basic_fee',
                  label: '基础每时次金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                this.formData[6] = level_type
                this.rule_level = [{over_number:0,over_salary:0}]
              }
              if(e.target.value === 4 || e.target.value === 10){
                obj = {
                  name: 'precent_fee',
                  label: '基础提成比例',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'%'}
                }
                this.formData[6] = level_type
                this.rule_level = [{over_number:0,over_salary_precent:0}]
              }
              if(e.target.value === 5){
                obj = {
                  name: 'basic_fee',
                  label: '保底金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                this.formData[6] = {}
                this.rule_level = [{over_number:0,over_salary:0}]
              }
              this.formData[3] = obj
            },
            handleRadio(e){
              this.radioIndex = e.target.value
            },
            handleSearch(e){
              let obj = {"q":e}
              this.getClassCategory(obj)
            },
            async handleRadioes(e){
              this.radioesIndex = e.target.value
              if(e.target.value === 1){
                this.radiosIndex = 4
                this.formData[4] = {}
                this.formData[5] = {}
                this.formData[9] = {}
                this.formData[11] = {}
                this.seleteItems.commissionType = C_ITEMS.commissionType
                await this.$nextTick()
                this.form.setFieldsValue({
                  rule_type:4,
                })
              }else{
                this.radiosIndex = 10
                let level_type = {
                  name: 'rule_level_type',
                  label: '梯度规则',
                  type: 'radio',
                  rules: [{ required: true, message: '请选择梯度规则!' }],
                  items: {
                    data: 'gradientRule',
                    label: 'label',
                    value: 'value'
                  },
                }
                let obj3 = {
                  name: 'precent_fee',
                  label: '基础提成比例',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'%'}
                }
                this.formData[3] = obj3
                this.formData[6] = level_type
                this.rule_level = [{over_number:0,over_salary_precent:0}]
                let obj4 = {
                  name: 'mkt_commision',
                  label: '市场提成',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入比例!' }],
                  items:{unit:'%'}
                }
                let obj5 = {
                  name: 'leader_commision',
                  label: '上级提成',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入比例!' }],
                  items:{unit:'%'}
                }
                let obj9 = {
                  name: 'channel_ids',
                  label: '来源渠道',
                  type: 'channel_ids',
                  rules: [{ required: true, message: '请选择来源渠道!' }],
                }
                let obj11 = {
                  name: 'contract_type_ids',
                  label: '合同类型',
                  type: 'contract_type_ids',
                  rules: [{ required: true, message: '请选择合同类型!' }],
                }
                this.seleteItems.commissionType = this.commissionType
                this.formData[4] = obj4
                this.formData[5] = obj5
                this.formData[9] = obj9
                this.formData[11] = obj11
                await this.$nextTick()
                this.form.setFieldsValue({
                  rule_type:10,
                })
              }
            },
            async setForm(){
              this.radiosIndex = this.item.rule_type
              this.radioesIndex = this.item.commision_type
              let obj = {}
              let formData = {
                rule_name:this.item.rule_name,
                rule_type:this.item.rule_type,
                studio_ids:[],
                course_cate_ids:[],
              }
              this.item.course_cate_ids.forEach(item=>{
                formData.course_cate_ids.push(Number(item))
              })
              this.seleteItems.studios.forEach(it=>{
                if(this.item.studio_ids.indexOf(it.studio_id.toString()) !=-1){
                  formData.studio_ids.push(it.studio_id)
                }
                it.children.forEach(i=>{
                  if(this.item.studio_ids.indexOf(i.studio_id.toString()) !=-1){
                    formData.studio_ids.push(i.studio_id)
                  }
                })
              })
              if(this.item.commision_type === 2){
                formData.commision_type = 2
                formData.contract_type_ids = this.item.contract_type_ids
                formData.leader_commision = this.item.leader_commision
                formData.mkt_commision = this.item.mkt_commision
                formData.channel_ids = []
                let obj4 = {
                  name: 'leader_commision',
                  label: '上级提成',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入比例!' }],
                  items:{unit:'%'}
                }

                let obj5 = {
                  name: 'mkt_commision',
                  label: '市场提成',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入比例!' }],
                  items:{unit:'%'}
                }
                
                
                let obj9 = {
                  name: 'channel_ids',
                  label: '来源渠道',
                  type: 'channel_ids',
                  rules: [{ required: true, message: '请选择来源渠道!' }],
                }
                let obj11 = {
                  name: 'contract_type_ids',
                  label: '合同类型',
                  type: 'contract_type_ids',
                  rules: [{ required: true, message: '请选择合同类型!' }],
                }
                this.seleteItems.commissionType = this.commissionType
                this.formData[4] = obj4
                this.formData[5] = obj5
                this.formData[9] = obj9
                this.formData[11] = obj11
                this.channels.forEach(it=>{
                  if(this.item.channel_ids.indexOf(it.value.toString()) !=-1){
                    formData.channel_ids.push(it.value)
                  }
                  it.children.forEach(i=>{
                    if(this.item.channel_ids.indexOf(i.value.toString()) !=-1){
                      formData.channel_ids.push(i.value)
                    }
                  })
                })
              }else{
                formData.commision_type = 1
              }
              if(this.item.rule_type === 1){
                obj = {
                  name: 'basic_fee',
                  label: '基础每课次金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                formData.basic_fee = this.item.basic_fee
                formData.rule_level_type = this.item.rule_level_type
              }
              if(this.item.rule_type === 2 || this.item.rule_type === 3){
                obj = {
                  name: 'basic_fee',
                  label: '基础每时次金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                formData.basic_fee = this.item.basic_fee
                formData.rule_level_type = this.item.rule_level_type
              }
              if(this.item.rule_type === 4 || this.item.rule_type === 10){
                obj = {
                  name: 'precent_fee',
                  label: '基础提成比例',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'%'}
                }
                formData.precent_fee = this.item.precent_fee
                formData.rule_level_type = this.item.rule_level_type
              }
              if(this.item.rule_type === 5){
                obj = {
                  name: 'basic_fee',
                  label: '保底金额',
                  type: 'inputs',
                  rules: [{ required: true, message: '请输入金额!' }],
                  items:{unit:'元'}
                }
                this.formData[6] = {}
                formData.basic_fee = this.item.basic_fee
              }
              this.formData[3] = obj
              this.rule_level = this.item.rule_level
              await this.$nextTick()
              await this.form.setFieldsValue(formData)
              this.loading = false
            }
            
        },
    }
</script>

<style lang="scss">
    .salary-self{
        &-add{
            color: #00cca2;
            border: 1px solid #00cca2;
            text-align: center;
            line-height: 27px;
            width: 140px;
            border-radius: 3px;
            margin-top: 10px;
            cursor:pointer;
        }
        &-add-btn{
            color: #00cca2;
            margin-left: 8px;
        }
    }
</style>