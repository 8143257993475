<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
        <div>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                    <a-form-item v-if='pageParams.totalCount < 15'>
                        <a-button  @click='showEditModal()' icon="plus">工资表头</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <!-- <a-table size="middle" :pagination="false" :bordered='false' rowKey="column_id"
                    :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{ index+1 }}</span>
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a-dropdown v-if='record.can_delete !=0'> 
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                            <a-menu slot="overlay">
                                <a-menu-item @click='showEditModal(record)' key="1">
                                编辑
                                </a-menu-item>
                                <a-menu-item key="2" @click="toDel(record)">
                                删除
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </a-table> -->
                <div class="payroll ant-table ant-table-small ant-table-scroll-position-left ant-table-middle">
                    <div class="ant-table-content">
                        <div class="ant-table-body">
                            <table class="payroll-table ant-table-thead">
                                <thead class="ant-table-thead">
                                <tr>
                                    <th>#</th>
                                    <th class='ant-table-align-center' style='text-align: left'>工资表头</th>
                                    <th style='text-align: right'>操作</th>
                                </tr>
                                </thead>
                                <draggable 
                                    class='ant-table-tbody' 
                                    :disable='dragDisable' 
                                    v-model="list" 
                                    element='tbody'
                                    :options="{draggable:'.payroll-item'}"
                                    :start='handleStart' 
                                    :update="datadragEnd"
                                    :move='allow'
                                     >
                                    <tr class="ng-scope" :class="index !=0 ? 'payroll-item':''" v-for="(item,index) in list" :key="index">
                                        <td class='ant-table-row ant-table-row-level-0 payroll-table-td'><span id="convertCurrency">{{index + 1}}</span></td>
                                        <td class='ant-table-row ant-table-row-level-0 payroll-table-td' style='text-align: left'><span class="cost_signprice">{{ item.column_name }}</span></td>
                                        <td class='ant-table-row ant-table-row-level-0 payroll-table-td' style='text-align: right'>
                                            <span class="discount_price">
                                                <a-dropdown v-if='item.can_delete !=0'> 
                                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                                                    <a-menu slot="overlay">
                                                        <a-menu-item @click='showEditModal(item)' key="1">
                                                        编辑
                                                        </a-menu-item>
                                                        <a-menu-item key="2" @click="toDel(item)">
                                                        删除
                                                        </a-menu-item>
                                                    </a-menu>
                                                </a-dropdown>
                                            </span>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <headerEditModal v-if="isEditModal" :item='modalData' />
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '工资表头', dataIndex: 'column_name', key: 'column_name' ,align: 'left',},
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }}
]

    import headerEditModal from './headerEditModal'
    import draggable from 'vuedraggable'
    export default {
        name:'editModal',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        props: {
          item:Object
        },
        components: {headerEditModal,draggable},
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
                ModalTitle: '工资模板设置',
                loading: false,
                visible: false,
                isEditModal: false,
                confirmLoading: false,
                columns,
                list:[],
                modalData:{},
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 20,
                    totalCount: 0
                },
                dragDisable:false,
            }
        },
        async created () {
            this.visible = true
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('financeSalaryColumnsAction', { })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async handleOk() {
                this.list.forEach((item,index)=>{
                    item.column_order = index
                })
                this.confirmLoading = true
                await this.$store.dispatch('financeSalaryUpdateOrderAction', {data:{columns:this.list}})
                    .then(res=>{
                        this.$message.success('操作成功~')
                        this.confirmLoading = false
                    })
                    .catch(err=>{
                        this.$message.success('操作失败~')
                        this.confirmLoading = false
                    })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            showEditModal(record) {
                this.modalData = record
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                  this.getList()
                }
                this.isEditModal = false
            },
            toDel(record){
                let that = this
                this.$confirm({
                    title: `您确定该表头？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('financeSalaryDeleteColumnsAction', { data: { column_id: record.column_id } })
                        if (res) {
                            that.$message.success('操作成功~')
                            that.getList()
                            resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            async datadragEnd (evt) {
                // console.log(evt)
                
            },
            handleStart(evt){
                // console.log(evt)
                
            },
            allow(evt){
                console.log(evt)
            }
            
            
        },
    }
</script>

<style lang="scss">
    .payroll{
        &-table{
            width:100%
        }
    }
</style>