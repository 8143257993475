<template>
  <a-modal v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer='null'
    @ok="handleOk" @cancel="handleCancel">
    
        <div class="zcxy">
            <a-page-header :title="ModalTitle">
                <template slot="tags">
                    <a-tag color="blue">
                        {{salary_confirm}}
                    </a-tag>
                </template>
                <template slot="extra">
                    <a-button @click="toConfirm" key="1" type="primary" v-if="salary_confirm =='待确认'">
                    结算确认
                    </a-button>
                </template>
                <template slot="extra">
                    <a-button @click="toExport" key="2" type="primary">
                    导出
                    </a-button>
                </template>
                <!-- <a-row type="flex">
                    <a-statistic title="状态" value="Pending" />
                    <a-statistic
                    title="Price"
                    prefix="$"
                    :value="568.08"
                    :style="{
                        margin: '0 32px',
                    }"
                    />
                    <a-statistic title="Balance" prefix="$" :value="3345.08" />
                </a-row> -->
            </a-page-header>
            
            <div class="clearfix table-tools">
            <div class="buttons">
                <!-- <a-form layout='inline'>
                <a-form-item>
                    <a-button>确认工资单</a-button>
                </a-form-item>
                </a-form> -->
            </div>
            <div class="search">
            </div>
            </div>
            <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="bill_id"
                :columns="columns" :dataSource="list" @change="handleChange">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="action" slot-scope="text,record">
                    <a href="javascript:;" @click="toDel(record)" v-if="salary_confirm =='待确认'">删除</a>
                </template>
            </a-table>
            </div>
            <div class="page">
            <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                </template>
                </a-pagination>
            </div>
        </div>
  </a-modal>
</template>

<script>
    import tableMixins from '@/common/mixins/table'
    import moment from 'moment'
    export default {
        name:'payrollEditModal',
        inject: ['parent'],
        mixins: [ tableMixins ],
        props: {
          item:Object
        },
        data() {
            return {
                loading: false,
                columns:[],
                list:[],
                ModalTitle: '',
                visible: false,
                confirmLoading: false,
                salary_confirm:'',
            }
        },
        created () {
            this.visible = true
            this.ModalTitle = `${this.item.salary_name}工资`
        },
        methods: {
            async getList(){
                this.loading = true
                this.searchParams.salary_id = this.item.salary_id
                let res = await this.$store.dispatch('financeSalaryBillAction', { data: this.searchParams })
                this.salary_confirm = res._data.salary.salary_confirm
                this.dealData(res)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            dealData(res){
                let fields = res._data.fields
                let columns = [
                    { title: '#', width:'50px', scopedSlots: { customRender: 'index' }, key: 'index'},
                    { title: '员工名称', dataIndex: 'employee_id', key: 'employee_id' },
                ]
                for (const key in fields) {
                    let obj = {title: fields[key], dataIndex: key, key: key, align: 'right',sorter:()=>{} }
                    columns.push(obj)
                }
                let action = { title: '操作', key: 'operation', align: 'right', width: 118, scopedSlots: { customRender: 'action' }}
                columns.push(action)
                this.columns = columns
            },
            handleOk() {},
            toDel(item){
                let that = this
                this.$confirm({
                    title: `您确定删除？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('financeSalaryDeleteBillAction', { data: { bill_id:item.bill_id } })
                        if (res) {
                        that.$message.success('操作成功~')
                        that.getList()
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList();
            },
            async toConfirm(){
                let that = this
                this.$confirm({
                    title: `您确定结算${this.item.salary_name}的工资？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('financeSalaryConfirmAction', { data: {salary_id:that.item.salary_id} })
                        if (res) {
                        that.$message.success('操作成功~')
                        that.getList()
                        that.parent.getList()
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })

            },
            async toExport(){
              this.exportLoading = true
              let res = await this.$store.dispatch('financeSalaryExportAction', {salary_id:this.item.salary_id})
              const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
              if ('download' in document.createElement('a')) { 
                  const link = document.createElement('a')
                  link.download = `${this.item.salary_name}.${moment().format('YYYY.MM.DD')}.xlsx`
                  link.style.display = 'none'
                  link.href = URL.createObjectURL(blob)
                  document.body.appendChild(link)
                  link.click()
                  URL.revokeObjectURL(link.href) 
                  document.body.removeChild(link)
              } else { //其他浏览器
                  navigator.msSaveBlob(blob, fileName)
              }
              this.exportLoading = false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>