<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]" placeholder="请输入表头名称" />
        <div style="color:#d9d9d9">注 : 最多可创建10个工资表头</div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'column_name',
    label: '表头名称',
    type: 'input',
    rules: [{ required: true, message: '请输入表头名称!' }]
  },
]
    export default {
        name:'headerEditModal',
        inject: ['parent'],
        props: {
          item:Object
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
                ModalTitle: '工资表头',
                visible: false,
                confirmLoading: false,
                formData,
                form: this.$form.createForm(this),
            }
        },
        async created () {
            this.visible = true
            let {item} = this
            await this.$nextTick()
            if(item){
              this.form.setFieldsValue({column_name:item.column_name})
            } 
        },
        methods: {
            async handleOk() {
                let { item,form } = this
                let params = await form.validateFields()
                if(item){
                   params.column_id = item.column_id 
                }
                this.confirmLoading = true
                await this.$store.dispatch(item?'financeSalaryUPdateColumnsAction':'financeSalaryAddColumnsAction', { data: params })
                    .then(res=>{
                        this.$message.success('操作成功~')
                        this.parent.hideEditModal(1)
                    })
                    .catch(err=>{
                        this.$message.success('操作失败~')
                        this.confirmLoading = false
                    })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            
        },
    }
</script>

<style lang="scss">

</style>