<template>
  <div class='crmUploadModal'>
  <a-modal :title="modalTitle" v-model="visible" centered :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="550px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <div class="uploadModal">
        <a-tabs :animated="false" :activeKey='activeKey' @change='tabClick'>
            <a-tab-pane disabled key="1" tab="">
            </a-tab-pane> 
            <a-tab-pane key="2" tab="上传文件">
                <div class="uploadModal-content">
                    <div>
                        <div class="uploadModal-content-title">
                            <span>一、请按照数据模板的格式准备要导入的数据。</span>
                            <span @click="toDown" style="color:#00cca2;cursor:pointer">点击下载</span>
                            <span>《工资单导入模板》</span>
                        </div>
                        <div style="color:#a9a9a9;font-size:12px;padding:10px 10px 10px 30px">
                            注意事项：<br/>
                            1、模板中的表头名称不能更改，表头行不能删除<br/>
                            <!-- 2、其中标*为必填项，必须填写<br/> -->
                            3、导入文件请勿超过10MB<br/>
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span>二、请填写结算名称</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-input style="width: 300px"  placeholder="请输入" v-model="name"/>
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span>三、请选择发薪月份</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-month-picker style="width: 300px"  :allowClear='false' placeholder="请选择" v-model="month" />
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span>四、请选择结算日期</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-date-picker style="width: 300px" :allowClear='false' placeholder="请选择" v-model="end_date"/>
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span>五、请选择需要导入的文件</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-upload
                                name="file"
                                multiple
                                accept=".xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                :beforeUpload="beforeUpload"
                            >   
                                <div style="display:flex">
                                    <a-input style="margin-right:10px;width:300px" :value="fileName" disabled />
                                    <a-button><a-icon type="upload" /> 选择文件 </a-button>
                                </div>
                            </a-upload>
                        </div>
                    </div>
                    <div style="text-align:right">
                        <a-button @click="handleCancel" style='margin-right:10px'>取消</a-button>
                        <a-button @click="toUpload" type="primary">确定</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane disabled key="3" tab="">
            </a-tab-pane>
            <a-tab-pane disabled key="4" tab="">
            </a-tab-pane>
            <a-tab-pane key="5" disabled tab="导入数据">
                <div style="height:250px;display:flex;justify-content:center;align-items:center">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <div>
                        <div class="result" style="text-align:center">
                            <a-result v-if="isSuccess" status="success" title="">
                                <div>已完成</div>
                                <div style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条,
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,  -->
                                    覆盖<span> {{uploadInfo.cover}} </span>条, 
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                            </a-result>
                            <a-result v-if="isError" title="">
                                <div >已取消</div>
                                <div style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条, 
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,  -->
                                    覆盖<span> {{uploadInfo.cover}} </span>条, 
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                                <!-- <div v-if='uploadInfo.error>0' style="text-align:center;cursor:pointer;">
                                    <span @click="toDownError" style="border-bottom: 1px solid #00cca2;color:#00cca2;">
                                        <a-icon color='#00cca2' type="download" />
                                        点击下载错误数据
                                    </span>
                                </div> -->
                            </a-result>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
  </a-modal>
  </div>
</template>
<script>
import url from '@/utils/URL'
import moment from 'moment'

export default {
    inject: ['parent'],
    name:'uploadModal',
    data(){
        return{
            loading:false,
            fetching: false,
            confirmLoading: false,
            visible: false,
            isSuccess:false,
            isError:false,
            fileName:'',
            fileList:[],
            activeKey:'2',
            modalTitle:'',
            consultants:[],
            import_to_employee:this.$store.getters.employee_id,
            studios:[],
            uploadInfo:{},
            month:'',
            name:'',
            end_date:''
        }
    },
    props:['isTab'],
    created() {
        this.visible = true
        this.getStudio()
        this.getConsultant()
        this.modalTitle = '导入工资单'
    },

    beforeDestroy() {
        this.visible = false
    },
    methods:{
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async handleOk() {
      this.confirmLoading=true;},
        handleCancel() {
            this.parent.hideUploadModal()
        },
        async getStudio() {
            this.fetching = true
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
            this.fetching = false
        },
        async getConsultant() {
            let res = await this.$store.dispatch('searchConsultantAction', {})
            this.consultants = res.data
        },
        beforeUpload(file){
            this.fileName = file.name
            this.fileList = file;
            return false
        },
        tabClick(a){
            if(a == '2'){
                this.activeKey = '2'
            }
        },
        async toUpload(){
            if(!this.name){
                this.$message.warning('请填写结算名称!')
                return false
            }
            if(!this.month){
                this.$message.warning('请选择发薪月份!')
                return false
            }
            if(!this.end_date){
                this.$message.warning('请选择结算日期!')
                return false
            }
            const { fileList } = this
            const formData = new FormData()
            formData.append('import_file', fileList)
            formData.append('salary_name', this.name)
            formData.append('salary_month', moment(this.month).startOf('month').format("YYYY-MM-DD"))
            formData.append('salary_end_date', moment(this.end_date).format("YYYY-MM-DD"))
            if(this.fileName){
                this.activeKey = '5'
                this.loading = true
                let res = await this.$store.dispatch('financeSalaryImportAction', formData)
                this.uploadInfo = res.data
                this.loading = false
                if(res.data.error > 0){
                    this.isError = true
                    this.$message.error(res.msg)
                }else{
                    this.isSuccess = true
                    this.$message.success('导入成功!')
                    this.parent.getList()
                }
            }else{
                this.$message.error('请选择导入文件!')
            }
            
        },
        async toDown(){
            let res = await this.$store.dispatch('financeSalaryExampleAction', {})
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = '工资单导入模板.xlsx'
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
        },
        async toDownError(){
            let res 
            if(this.isTab == '0'){
                res = await this.$store.dispatch('cLeadsErrorFileAction', {error_file:this.uploadInfo.error_file})
            }else{
                res = await this.$store.dispatch('cCustomerErrorFileAction', {error_file:this.uploadInfo.error_file})
            }
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = '导入错误数据.xlsx'
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
        }
    }
}
</script>
<style lang="scss">
    .ant-message{
        z-index:4100
    }
    .ant-select-dropdown{
        z-index: 5000;
    }
    .uploadModal{
        .ant-tabs-bar{
            border-bottom:none
        }
        .ant-upload-list-item{
            display: none;
        }
        .ant-result-content{
            padding: 15px;
        }
    }
</style>