<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form >
        <a-form-item label="绩效规则" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
            <a-select v-model="rule_id" allowClear showSearch :filterOption="filterOption" @search="handleSearch" placeholder="请选择" >
                <a-select-option v-for="(d, index) of list" :key="index" :value="d['rule_id']">{{ d['rule_name'] }}</a-select-option>
            </a-select>
        </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
    export default {
        name:'editModal',
        inject: ['parent'],
        props: {
          item:Object
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
                ModalTitle: '设置绩效',
                visible: false,
                confirmLoading: false,
                list:[],
                rule_id:undefined,
            }
        },
        async created () {
            this.visible = true
            this.getList()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(val){
                let res = await this.$store.dispatch('financeSalaryCalculationRulesAction', {search:{rule_name:val}})
                this.list = res.items
                if(this.item.class_calculation_rule){
                    this.rule_id = this.item.class_calculation_rule.rule_id
                }
            },
            async handleOk() {
                if(!this.rule_id){
                    this.$message.warning('请选择绩效规则')
                    return false
                }
                let params = {
                    rule_id:this.rule_id,
                    employee_id:this.item.employee_id,
                    class_id:this.item.class_id,
                }
                this.confirmLoading = true
                await this.$store.dispatch('financeSalaryCalculationSetCalculationAction', {data:params})
                    .then(res=>{
                        this.$message.success('操作成功~')
                        this.parent.hideEditModal(1)
                    })
                    .catch(err=>{
                        this.$message.error('操作失败~')
                        this.confirmLoading = false
                    })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            handleSearch(val){
                this.getList(val)
            },
        },
    }
</script>