<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form >
        <a-form-item label="选择时间" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-range-picker v-model="timer" :allowClear='false'/>
            <div style="line-height:24px;color:#d8d8d8">
                注: 点击既可以生成老师绩效工资,单次导出绩效时间范围不可超过三个月2
            </div>
        </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
    import moment from 'moment'
    export default {
        name:'createEditModal',
        inject: ['parent'],
        props: {
          employee_id:Number,
          employee_name:String
        },
        data() {
            return {
                ModalTitle: '绩效结算',
                visible: false,
                confirmLoading: false,
                timer:[moment(moment().startOf('month')).add(-1, 'month'),moment(moment().endOf('month')).add(-1, 'month')]
            }
        },
        async created () {
            this.visible = true
        },
        methods: {
            async handleOk() {
                if(this.timer.length == 0){
                    this.$message.warning('请选择时间!!')
                    return false
                }
                let start_date = moment(this.timer[0]).format("YYYY-MM-DD")
                let end_date = moment(this.timer[1]).format("YYYY-MM-DD")
                let dayNum = moment(end_date).diff(moment(start_date),'days')
                if(dayNum > 90){
                    this.$message.warning('选择的时间范围不能超过三个月!!')
                    return false
                }
                let params = {
                    start_date:start_date,
                    end_date:end_date,
                    employee_id:this.employee_id
                }
                this.confirmLoading = true
                let res = await this.$store.dispatch('financeSalaryCalculationCalculationExportAction', params)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `${this.employee_name}.${params.start_date}-${params.end_date}.xls`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.$message.success('操作成功~')
                this.parent.hideEditModal(1)
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
        },
    }
</script>