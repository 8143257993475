<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>工资结算</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">工资单<a-badge :count="0" /></span>
          <payroll v-if="activeKey === '1'" />
        </a-tab-pane>
        <!-- <a-tab-pane key="2">
          <span slot="tab">绩效设置<a-badge :count="0" /></span>
          <setup v-if="activeKey === '2'" />
        </a-tab-pane> -->
        <a-tab-pane key="3">
          <span slot="tab">绩效规则<a-badge :count="0" /></span>
          <rule v-if="activeKey === '3'" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import rule from './rule'
import setup from './setup'
import payroll from './payroll'
export default {
  components: {
      setup,
      rule,
      payroll
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

