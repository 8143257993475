<template>
  <a-modal title="结算编辑" v-model="visible" :confirmLoading="confirmLoading" :zIndex='4100' cancelText="取消" okText="保存" width="500px" @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item v-for="(item, index) of formData" :key="index" 
                :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-input placeholder="请输入" v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-month-picker style="width: 100%"  :allowClear='false' v-if="item.type === 'month'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" />
                <a-date-picker style="width: 100%" :allowClear='false' v-if="item.type === 'time'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]"/>
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'salary_name',
        label: '结算名称',
        type: 'input',
        rules: [{ required: true, message: '请输入标题!' }]
    },
    {
        name: 'salary_month',
        label: '发薪月份',
        type: 'month',
        rules: [{ required: true, message: '请选择发薪月份!' }]
    },
    {
        name: 'salary_end_date',
        label: '结算日期',
        type: 'time',
        rules: [{ required: true, message: '请选择结算日期!' }]
    }
]
    import moment from 'moment'
    export default {
        name:'detailEditModal',
        inject: ['parent'],
        props: {
            item: Object
        },
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 15 },
                form: this.$form.createForm(this),
            }
        },
        async created () {
            this.visible = true
            await this.$nextTick()
            this.form.setFieldsValue({
                salary_name: this.item.salary_name,
                salary_month: moment(this.item.salary_month),
                salary_end_date: this.item.salary_end_date ? moment(this.item.salary_end_date) : null,
            })
        },
        methods: {
            async handleOk() {
                const { item, form } = this
                let params = await form.validateFields()
                params.salary_month = moment(params.salary_month).startOf('month').format("YYYY-MM-DD")
                params.salary_end_date = moment(params.salary_end_date).format("YYYY-MM-DD")
                params.salary_id = item.salary_id
                this.confirmLoading = true
                await this.$store.dispatch('financeSalaryUpdateAction', { data: params})
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败!')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>