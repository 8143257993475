<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="upload" @click="showUploadModal()">工资单导入</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="showEditModal()">工资模板设置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>结算名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.salary_name" placeholder="请输入结算名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>发薪月份</span>
              </template>
              <a-range-picker
                :placeholder="['开始月份', '结束月份']"
                format="YYYY-MM"
                :value="monthValue"
                :mode="['month', 'month']"
                @panelChange="handlePanelChange"
                @change="handleMonth"
              />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>结算状态</span>
              </template>
              <a-select placeholder="请选择结算状态" allowClear style="width: 160px" v-model="searchParams.search.salary_confirm">
                <a-select-option v-for="(d, index) of salaryStatus" :key="index" :value="d.value">{{ d.label }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="salary_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="name" slot-scope="text, record">
            <div @click="showPayrollEditModal(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
          </template>
          <template slot="end" slot-scope="text, record">
            <div>{{text?text:'--'}}</div>
          </template>
          <template slot="month" slot-scope="text, record">
            <div>{{text?moment(text).format('YYYY-MM'):'--'}}</div>
          </template>
          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item @click="showDetailEditModal(record)" key="1">
                  编辑
                </a-menu-item>
                <a-menu-item @click="toDel(record)" key="2"  v-if="record.salary_confirm =='待确认'">
                  删除
                </a-menu-item>
                <a-menu-item @click="toExport(record)" key="3">
                  导出
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="isEditModal" :item='modalData' />
    <uploadModal v-if="isUploadModal" isTab='0'/>
    <detailEditModal v-if="isDetailEditModal" :item='modalData' />
    <payrollEditModal v-if="isPayrollEditModal" :item='modalData' />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '结算名称', dataIndex: 'salary_name', key: 'salary_name' ,scopedSlots: { customRender: 'name' }},
  { title: '发薪月份', dataIndex: 'salary_month', key: 'salary_month' ,scopedSlots: { customRender: 'month' }},
  { title: '结算数量', dataIndex: 'total_conut', key: 'total_conut' ,align:"right"},
  { title: '结算金额', dataIndex: 'total_salary', key: 'total_salary' ,align:"right"},
  { title: '结算日期', dataIndex: 'salary_end_date', key: 'salary_end_date' ,scopedSlots: { customRender: 'end' },align:"right"},
  { title: '结算状态', dataIndex: 'salary_confirm', key: 'salary_confirm' ,align:"center"},
  { title: '经办人', dataIndex: 'created_by', key: 'created_by' ,align:"center"},
  { title: '操作', key: 'operation', align: 'right', width: 118, scopedSlots: { customRender: 'action' }}
]

    import tableMixins from '@/common/mixins/table'
    import editModal from './editModal'
    import uploadModal from './uploadModal'
    import detailEditModal from './detailEditModal'
    import payrollEditModal from './payrollEditModal'
    import moment from 'moment'
    import C_ITEMS from '@/utils/items'
    export default {
        name:'payroll',
        mixins: [ tableMixins ],
        data() {
            return {
                loading: false,
                columns,
                list:[],
                monthValue:[],
                isEditModal:false,
                isDetailEditModal:false,
                isPayrollEditModal:false,
                exportLoading:false,
                isUploadModal:false,
                salaryStatus:C_ITEMS.salaryStatus,
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components:{ 
          editModal,
          uploadModal,
          detailEditModal,
          payrollEditModal
        },
        methods: {
            moment,
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('financeSalaryAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            showEditModal() {
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                  this.getList()
                }
                this.isEditModal = false
                this.isDetailEditModal = false
                this.isPayrollEditModal = false
            },
            showDetailEditModal(item){
              this.modalData = item
              this.isDetailEditModal = true
            },
            showPayrollEditModal(item){
              this.modalData = item
              this.isPayrollEditModal = true
            },
            handlePanelChange(e){
              this.monthValue = e
              if(e.length > 0){
                this.searchParams.search.start_date = moment(e[0]).startOf('month').format("YYYY-MM-DD")
                this.searchParams.search.end_date = moment(e[1]).endOf('month').format("YYYY-MM-DD")
                this.searchList()
              }
            },
            showUploadModal(){
              this.isUploadModal = true
            },
            hideUploadModal(){
              this.isUploadModal = false
            },
            handleMonth(e){
              this.monthValue = e
              delete this.searchParams.search.start_date
              delete this.searchParams.search.end_date
            },
            toDel(item){
              let that = this
              this.$confirm({
                title: `您确定删除该工资单？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('financeSalaryDeleteAction', {data:{salary_id:item.salary_id}})
                    if (res) {
                      that.$message.success('操作成功~')
                      that.getList()
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            },
            async toExport(item){
              this.exportLoading = true
              let res = await this.$store.dispatch('financeSalaryExportAction', {salary_id:item.salary_id})
              const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
              if ('download' in document.createElement('a')) { 
                  const link = document.createElement('a')
                  link.download = `${item.salary_name}.${moment().format('YYYY.MM.DD')}.xls`
                  link.style.display = 'none'
                  link.href = URL.createObjectURL(blob)
                  document.body.appendChild(link)
                  link.click()
                  URL.revokeObjectURL(link.href) 
                  document.body.removeChild(link)
              } else { //其他浏览器
                  navigator.msSaveBlob(blob, fileName)
              }
              this.exportLoading = false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>