<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="primary" icon="plus" @click="showEditModal()">新增规则</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>规则名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.rule_name" placeholder="请输入规则名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-select v-model="searchParams.search.commision_type" allowClear placeholder="请选择结算类型" style="width: 160px">
              <a-select-option v-for="(item, index) of commissionType" :key="index" :value="item.value">{{ item.label }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="rule_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="rule_name" slot-scope="text, record">
            <div @click="showRecord(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
          </template>
          <template slot="rule_type" slot-scope="text">
            <span v-if="item.value == text" v-for="(item,index) in ruleType" :key="index">{{item.label}}</span>
          </template>
          <template slot="rule_level_type" slot-scope="text">
            <span>{{text == 1? "分段梯度":"最高梯度"}}</span>
          </template>
          <template slot="basic_fee" slot-scope="text,record">
            <span>{{record.precent_fee?`${record.precent_fee}%`:record.basic_fee}}</span>
          </template>
          <template slot="leader_commision" slot-scope="text,record">
            <span>{{record.leader_commision?`${record.leader_commision}%`:'-'}}</span>
          </template>
          <template slot="mkt_commision" slot-scope="text,record">
            <span>{{record.mkt_commision?`${record.mkt_commision}%`:'-'}}</span>
          </template>
          <template slot="contract_type_ids" slot-scope="text,record">
            <span style="margin-right:3px" v-for="(it,ind) in plainOptions" :key="ind" v-if="text.indexOf(it.value) != -1">{{it.label}}</span>
            <span v-if="text.length == 0">-</span>
          </template>
          <template slot="studio_names" slot-scope="text, record , index">
              <span v-if="record.studio_names.length === 0">--</span>
              <a-dropdown v-else>
                  <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                    {{record.studio_names[0]}}等{{record.studio_names.length}}个校区<a-icon type="caret-down" />
                  </div>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.studio_names" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>
          <template slot="course_cate_name" slot-scope="text, record , index">
              <span v-if="record.course_cate_name.length === 0">--</span>
              <a-dropdown v-else>
                  <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                    {{record.course_cate_name[0]}}等{{record.course_cate_name.length}}个科目<a-icon type="caret-down" />
                  </div>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.course_cate_name" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>
          <template slot="channel_names" slot-scope="text, record , index">
              <span v-if="record.channel_names.length === 0">--</span>
              <a-dropdown v-else>
                  <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                    {{record.channel_names[0]}}等{{record.channel_names.length}}个渠道<a-icon type="caret-down" />
                  </div>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.channel_names" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>
          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item key="3" @click="showRecord(record)">
                  结算记录
                </a-menu-item>
                <a-menu-item key="4" @click="showReview(record)">
                  重新结算
                </a-menu-item>
                <a-menu-item key="1" @click="showEditModal(record)">
                  编辑规则
                </a-menu-item>
                <a-menu-item key="2" @click="toDel(record)">
                  删除规则
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="isEditModal" :item='modalData' :isEdit="isEdit" />
    <recordList v-if="isRecordList" :item='modalData'/>
    <review v-if="isReview" :item='modalData'/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '规则名称', dataIndex: 'rule_name', key: 'rule_name' ,scopedSlots: { customRender: 'rule_name' }},
  { title: '结算类型', dataIndex: 'commision_type_name', key: 'commision_type_name',scopedSlots: { customRender: 'commision_type_name' }},
  { title: '合同类型', dataIndex: 'contract_type_ids', key: 'contract_type_ids',scopedSlots: { customRender: 'contract_type_ids' },align:'center'},
  { title: '提成方式', dataIndex: 'rule_type', key: 'rule_type' ,scopedSlots: { customRender: 'rule_type' }},
  { title: '梯度规则', dataIndex: 'rule_level_type', key: 'rule_level_type' ,scopedSlots: { customRender: 'rule_level_type' }},
  { title: '基础金额', dataIndex: 'basic_fee', key: 'basic_fee', scopedSlots: { customRender: 'basic_fee' },align:'center'},
  { title: '上级提成', dataIndex: 'leader_commision', key: 'leader_commision', scopedSlots: { customRender: 'leader_commision' },align:'center'},
  { title: '市场提成', dataIndex: 'mkt_commision', key: 'mkt_commision', scopedSlots: { customRender: 'mkt_commision' },align:'center'},
  { title: '适用校区', dataIndex: 'studio_names', key: 'studio_names', scopedSlots: { customRender: 'studio_names' },align:'center'},
  { title: '适用科目', dataIndex: 'course_cate_name', key: 'course_cate_name', scopedSlots: { customRender: 'course_cate_name' },align:'center'},
  { title: '适用渠道', dataIndex: 'channel_names', key: 'channel_names', scopedSlots: { customRender: 'channel_names' },align:'center'},
  { title: '操作账户', dataIndex: 'created_by', key: 'created_by' },
  { title: '操作', key: 'operation', align: 'right', width: 80, scopedSlots: { customRender: 'action' }}
]

    import tableMixins from '@/common/mixins/table'
    import editModal from './editModal'
    import review from './review'
    import recordList from './recordList'
    import C_ITEMS from '@/utils/items'
    export default {
        name:'rule',
        mixins: [ tableMixins ],
        data() {
            return {
                loading: false,
                columns,
                list:[],
                isEdit:0,
                isEditModal:false,
                isRecordList:false,
                isReview:false,
                commissionType:[
                  { label: '课时绩效', value: 1 },
                  { label: '合同绩效', value: 2 },
                ],
                ruleType:[
                  { label: '授课课次', value: 1 },
                  { label: '授课课时', value: 2 },
                  { label: '学员课时', value: 3 },
                  { label: '消课金额', value: 4 },
                  { label: '到课人次', value: 5 },
                  { label: '签约金额', value: 10 }
                ],
                plainOptions: [
                  { label: '新签', value: '1' },
                  { label: '续费', value: '2' },
                  { label: '转介绍', value: '3' },
                ]
            }
        },
        provide() {
            return {
            parent: this
            }
        },
        components:{ editModal, recordList, review },
        methods: {
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('financeSalaryCalculationRulesAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            showEditModal(record) {
                if(record){
                  this.isEdit = 1
                }else{
                  this.isEdit = 0
                }
                this.modalData = record
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                  this.getList()
                }
                this.isEditModal = false
            },
            showRecord(record){
              this.modalData = record
              this.isRecordList = true
            },
            showReview(record){
              this.modalData = record
              this.isReview = true
            },
            hideReview(val){
              if(val){
                this.getList()
              }
              this.isReview = false
            },
            hideRecord(){
              this.isRecordList = false
            },
            toDel(record){
              let that = this
              this.$confirm({
                title: `您确定删除该规则？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('financeSalaryCalculationDeleteRulesAction', { data: { rule_id: [record.rule_id] } })
                    if (res) {
                      that.getList()
                      that.$message.success('操作成功~')
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>